import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaMoneyBillWave, FaPlus, FaCopy, FaArrowRight, FaArrowLeft as FaArrowBackward, FaWallet } from 'react-icons/fa';
import { AiOutlineClockCircle, AiOutlineSetting } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import api from '../api/utils'; // Assuming this is the API utility
import NewBottomBar from './newBottomBar';

const Updated_wh = () => {
  const [expandedRow, setExpandedRow] = useState(null); // Track the currently expanded row
  const [withdrawals, setWithdrawals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Track the total pages
  const [selectedStatus, setSelectedStatus] = useState('all'); // Track the selected status
  const itemsPerPage = 5; // Define items per page

  const navigate = useNavigate();

  // Function to format status with colors
  const formatStatus = (status) => {
    switch (status) {
      case 0:
        return <span className="text-yellow-500">Pending</span>;
      case 1:
        return <span className="text-green-500">Accepted</span>;
      case 2:
        return <span className="text-red-500">Rejected</span>;
      case 9:
        return <span className="text-gray-500">All</span>; // For clarity
      default:
        return <span>Unknown</span>;
    }
  };
  

  // Function to format payments based on UPI ID and account number
  const formatPayments = (accountDetails) => {
    if (accountDetails) {
      const { upi_id, account_number } = accountDetails;
      if (upi_id && account_number) {
        return `${upi_id} and ****${account_number.slice(-4)}`;
      } else if (upi_id) {
        return upi_id;
      } else if (account_number) {
        return account_number.slice(-4);
      }
    }
    return 'No Payment Info';
  };

  // Custom function to format the timestamp
  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp * 1000); // Convert to milliseconds
      const formattedDate = date.toLocaleDateString('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      const formattedTime = date.toLocaleTimeString('en-IN', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // 24-hour format
      });
      return `${formattedDate} ${formattedTime}`;
    }
    return 'No Timestamp';
  };

    useEffect(() => {
      const fetchWithdrawals = async (page) => {
        setIsLoading(true);
        setError(null);
        try {
          const authToken = localStorage.getItem('auth_token');
          
          // Correct the status query logic here
          const statusQuery = selectedStatus === '9' || selectedStatus === 'all' ? '9' : selectedStatus;
          
          const response = await api.get(`/user/get-withdraw-forms?page=${page}&limit=${itemsPerPage}&status=${statusQuery}`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });
    
          const processedWithdrawals = response.data.items.map((withdrawal) => ({
            ...withdrawal,
            status: formatStatus(withdrawal.status),
            payments: formatPayments(withdrawal.AccountDetails),
            created_at: formatTimestamp(withdrawal.created_at),
          }));
    
          setWithdrawals(processedWithdrawals);
          setTotalPages(Math.ceil(response.data.total_items / itemsPerPage)); // Set total pages
          if (response.data.total_items === 0) {
            setError('No results found');
          }
        } catch (err) {
          setError(err.message || 'Error loading data');
        } finally {
          setIsLoading(false);
        }
      };
    
      fetchWithdrawals(currentPage); // Fetch withdrawals for the current page
    }, [currentPage, selectedStatus]);
  

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index); // Toggle row expansion
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-3">
          <FaArrowLeft className="text-xl"   onClick={() => navigate(-1)}/>
          <h1 className="text-xl font-bold">Withdrawal History</h1>
        </div>
        {/* <div className="bg-blue-600 p-2 rounded-full">
          <FaPlus className="text-white" />
        </div> */}
      </div>

      {/* Filter Dropdown */}
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-semibold">Transactions</h2>
        <select
          className="bg-[#282c2c] text-white p-2 rounded"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value="9">All</option>
          <option value="0">Pending</option>
          <option value="1">Accepted</option>
          <option value="2">Rejected</option>
        </select>
      </div>

      {/* Transaction Table */}
      <div className="bg-[#1a1a1a] rounded-lg p-4 mb-4">
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-red-500">No Results found</p>
        ) : withdrawals.length === 0 ? (
          <p className="text-yellow-500">No results found</p>
        ) : (
          <table className="w-full table-auto">
            <thead className="text-yellow-500 border-b border-gray-500">
              <tr>
                <th className="py-2 text-left">Payment Mode</th>
                <th className="py-2">Amount</th>
                <th className="py-2 text-right">Status</th>
              </tr>
            </thead>
            <tbody className="text-white">
              {withdrawals.map((transaction, index) => (
                <React.Fragment key={index}>
                  <tr className="border-b border-gray-700 cursor-pointer" onClick={() => toggleRow(index)}>
                    <td className="py-2 text-left">{transaction.AccountDetails.bank_name || 'UPI'}</td>
                    <td className="py-2 text-right">₹ {transaction.amount}</td>
                    <td className="py-2 text-center">{transaction.status}</td>
                  </tr>
                  {expandedRow === index && (
                    <tr className="text-gray-400 text-sm">
                      <td colSpan="3" className="py-2 pl-4">
                        <p>Payment Details: {transaction.payments}</p>
                        <p>
                          Transaction ID: {transaction.reference_transaction_id}{' '}
                          <FaCopy className="inline-block text-gray-500 ml-2 cursor-pointer" />
                        </p>
                        <p>Date & Time: {transaction.created_at}</p>
                        {transaction.remarks && <p className="text-red-500">Remarks: {transaction.remarks}</p>}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Pagination */}
      <div className="flex justify-center items-center space-x-2 mb-24">
        <FaArrowBackward
          className={`text-gray-400 cursor-pointer ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        <p className="text-sm">
          {currentPage} of {totalPages ? totalPages : 1}
        </p>
        <FaArrowRight
          className={`text-gray-400 cursor-pointer ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`}
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </div>

        <NewBottomBar />

    </div>
  );
};

export default Updated_wh;
