import React, { useEffect, useState } from 'react'; 
import { FaUser, FaCopy, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';
import TopBackNav from './TopBackNav';

const ReferralCard = ({ name, id, commission, deposit, totalBets, isExpanded, toggleExpand }) => {
  const [copySuccess, setCopySuccess] = useState('');
  const [copyLinkSuccess, setCopyLinkSuccess] = useState('');
  const copyToClipboard = () => {
    navigator.clipboard.writeText(id)
        .then(() => setCopySuccess('Copied!'))
        .catch(() => setCopySuccess('Failed to copy!'));
  };

  return (
    <div className="bg-[#1a1a1a] p-4 rounded-lg mb-3">
      {/* Card Header */}
      <div className="flex items-center justify-start cursor-pointer" onClick={toggleExpand}>
        <div className="flex items-center space-x-4">
          <div className="text-2xl">
            <FaUser />
          </div>
          <div>
            <h2 className="font-bold text-lg">{name}</h2>
            <div className="flex items-center space-x-2">
              <p className="text-sm">{id}</p>
              {/* Display copy success messages */}
              {copySuccess && alert("Referral Code copied")}
              {copyLinkSuccess && alert("Link copied successfully")}
              <FaCopy className="text-gray-400" onClick={copyToClipboard}/>
            </div>
          </div>
        </div>
        <div className="ml-auto text-right">
          <p className="text-[0.9rem] text-gray-300">Your commission</p>
          <p className="font-bold text-xl">₹{commission}</p>
        </div>
      </div>

      {/* Card Body (Expanded Content) */}
      {isExpanded && (
        <div className="mt-2 text-sm text-gray-300">
          <p>Total Deposit: ₹{deposit}</p>
          <p>Total Bets: ₹{totalBets}</p>
        </div>
      )}
    </div>
  );
};

const Referrals = () => {
  const navigate = useNavigate();
  const [referralData, setReferralData] = useState([]);
  const [expandedCards, setExpandedCards] = useState({});

  // Fetch referral data from API
  useEffect(() => {
    const fetchReferralData = async () => {
      const authToken = localStorage.getItem('auth_token'); // Get token from localStorage
      if (!authToken) {
        console.error('No auth token found');
        return;
      }

      try {
        const response = await fetch('https://api.chidiyakabutar.com/user/get-referral-stats', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        });

        const data = await response.json();
        setReferralData(data.referred_user_data || []); // Ensure it's always an array
      } catch (error) {
        console.error('Error fetching referral data:', error);
      }
    };

    fetchReferralData();
  }, []);

  const toggleCardExpand = (index) => {
    setExpandedCards((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="min-h-screen bg-black text-white p-4 mb-16 mont-font">
      {/* Header */}
      <TopBackNav heading="Your Referrals" />

      {/* Referral List */}
      {referralData.length === 0 ? (
        <p className="text-center text-gray-400">No referrals given</p>
      ) : (
        referralData.map((referral, index) => (
          <ReferralCard
            key={referral.id}
            name={referral.name}
            id={referral.id}
            commission={referral.commission_by_user}
            deposit={referral.deposit}
            totalBets={referral.total_bets_amount}
            isExpanded={!!expandedCards[index]}
            toggleExpand={() => toggleCardExpand(index)}
          />
        ))
      )}

      <NewBottomBar />
    </div>
  );
};

export default Referrals;
