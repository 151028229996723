import React, { useEffect, useState } from 'react';
import {
  FaWallet,
  FaRegCreditCard,
  FaUniversity,
  FaUserFriends,
  FaMoneyBillWave,
  FaPowerOff,
  FaRupeeSign,
  FaTelegramPlane,
  FaCopy
} from 'react-icons/fa';
import {
  AiOutlineBarChart,
  AiOutlineClockCircle,
  AiOutlineNotification,
  AiOutlineSetting,
} from 'react-icons/ai';
import { GiTrophy } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from './Loader';
import NewBottomBar from './newBottomBar';
import { fetchAvatarImage } from '../api/avatarUtils'; // Import the utility function
import {CiInboxIn, CiInboxOut, CiSaveDown1, CiSaveUp1, CiShop} from 'react-icons/ci'
import {PiHandDepositFill,PiHandWithdrawFill} from 'react-icons/pi'
import { MdSupportAgent } from 'react-icons/md';


const UpdatedProfile = () => {
  const [activeTab, setActiveTab] = useState('Deposit');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // Popup state
  const [passwordDetails, setPasswordDetails] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [avatarImage, setAvatarImage] = useState(null); 
  const [userProfile, setUserProfile] = useState({
    today_earnings: 0,
    total_earnings: 0,
    total_deposits: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      const avatarImageUrl = await fetchAvatarImage();
      setAvatarImage(avatarImageUrl);
      try {
        const token = localStorage.getItem('auth_token');
        if (!token) {
          console.error('No auth_token found in localStorage');
          return;
        }

        // Fetch today's earnings
        const earningsResponse = await fetch(
          'https://api.chidiyakabutar.com/user/todays-earnings',
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const earningsData = await earningsResponse.json();

        // Fetch total deposits
        const depositsResponse = await fetch(
          'https://api.chidiyakabutar.com/user/get-deposits',
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const depositsData = await depositsResponse.json();

        // Update state with fetched data (merge with existing state)
        setUserProfile((prevUserProfile) => ({
          ...prevUserProfile,
          today_earnings: earningsData.total_winnings || 0,
          total_earnings: earningsData.total_winnings || 0,
          total_deposits: depositsData.total_depositions || 0,
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Fetch user profile data
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const authToken = localStorage.getItem('auth_token');
        if (!authToken) {
          navigate('/');
          return;
        }

        const response = await axios.get(
          'https://api.chidiyakabutar.com/user/me',
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        // Merge user data with existing state
        setUserProfile((prevUserProfile) => ({
          ...prevUserProfile,
          ...response.data.user,
        }));
        console.log(response.data.user.id)
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUserProfile();
  }, [navigate]);

  // Handle password change submission
  const handlePasswordSubmit = async () => {
    try {
      const authToken = localStorage.getItem('auth_token');
      if (!authToken) {
        navigate('/');
        return;
      }

      const response = await axios.patch(
        'https://api.chidiyakabutar.com/user/change-password',
        {
          old_password: passwordDetails.oldPassword,
          new_password: passwordDetails.newPassword,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status !== 200) {
        setError('Failed to change password. Please try again.');
      } else {
        setShowPasswordModal(false);
        setPasswordDetails({
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
        alert('Password changed successfully!');
      }
    } catch (error) {
      setError('An error occurred while changing the password.');
    }
  };

  const handleCopyUID = () => {
    navigator.clipboard.writeText(userProfile.id);
    alert('UID copied to clipboard!');
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    setShowLanguageMenu(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('auth_token');
    navigate('/');
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!userProfile) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const handleForgotPassword = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="min-h-screen text-white mont-font">
      {/* Header */}
      <div className="flex items-center justify-between px-4 py-4">
        <div className="flex items-center space-x-4">
          <img
            src={avatarImage || 'https://via.placeholder.com/50'}
            alt="Profile"
            className="w-12 h-12 rounded-full"
                onClick={() => {navigate('/change-avatar')}}/>
          <div>
            <h1 className="text-lg font-bold">
              Hello, {userProfile.name || 'User'} !
            </h1>
            <p className="text-sm text-gray-400 flex">UID: {userProfile.id}
              <FaCopy
                className="ml-2 cursor-pointer"
                onClick={handleCopyUID} // Copy UID on button click
              />
            </p>
          </div>
        </div>
        <div className="p-4 mb-2 bg-gray-800 rounded-full pb-4">
          <FaPowerOff className="text-2xl" onClick={handleLogout} />
        </div>
      </div>

{/* Summary Section */}
<div className="py-4 px-4">
<h2 className="text-md font-bold">My Summary</h2>
<div className="flex gap-2 mt-4 overflow-x-auto">
  <div className="bg-[#2b2c2c] p-4 rounded-lg flex flex-col items-center justify-center min-w-[200px] py-8">
    <p className="text-green-500 text-2xl font-bold">₹ {userProfile.today_earnings || 0}</p>
    <p className="text-sm">Today's Winnings</p>
  </div>
  {/* <div className="bg-[#2b2c2c] p-4 rounded-lg flex flex-col items-center justify-center min-w-[200px]">
    <p className="text-red-500 text-2xl font-bold">₹ {userProfile.total_earnings || 0}</p>
    <p className="text-sm">Total Winnings</p>
  </div> */}
  <div className="bg-[#2b2c2c] p-4 rounded-lg flex flex-col items-center justify-center min-w-[200px]">
    <p className="text-green-500 text-2xl font-bold">₹ {userProfile.total_deposits || 0}</p>
    <p className="text-sm">Today's Deposits</p>
  </div>
</div>
</div>

{/* Quick Action Section */}
<div className="py px-4  font-bold">
  <h2 className="text-md font-bold">Quick Action</h2>
  <div className="grid grid-cols-3 gap-3 mt-2 bg-[#1a1a1a] rounded-lg mb-2 p-3">
    <div className="bg-[#2b2c2c] p-8 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/wallet')}>
      
      <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
          <FaWallet className="text-2xl" />
      </div>
      <p className="text-sm">Wallet</p>
    </div>
    <div className="bg-[#2b2c2c] p-8 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/deposit-zone')}>
      
    <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
      <PiHandDepositFill className="text-2xl" />
      </div>
      <p className="text-sm">Deposit</p>
    </div>
    <div className="bg-[#2b2c2c] p-8 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/withdraw-zone')}>
      
    <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
      <PiHandWithdrawFill className="text-2xl" />
      </div>
      <p className="text-sm">Withdraw</p>
    </div>
      <div
      className="bg-[#2b2c2c] p-8 rounded-lg flex flex-col items-center justify-center py-4 cursor-pointer"
      onClick={handleForgotPassword}
    >
      <div className="p-4 mb-2 bg-[#1a1a1a] rounded-full">
        <MdSupportAgent className="text-2xl" />
      </div>
      <p className="text-sm text-center">24x7 Support</p>
    </div>

    <div className="bg-[#2b2c2c] p-8 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/bonus')}>
      
    <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
      <FaRupeeSign className="text-2xl font-bold" />
      </div>
      <p className="text-sm">Bonus</p>
    </div>
  </div>
</div>

{/* My Profile Section */}
<div className="py-1 px-4 mb-16">
  <h2 className="text-md font-bold">My Profile</h2>
  <div className="grid grid-cols-3 gap-3 mt-2 text-center  bg-[#1a1a1a] p-3 rounded-lg mb-4 font-bold">
    <div className="bg-[#2b2c2c] p-8 px-0 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/user-stats')}>
      
    <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
      <AiOutlineBarChart className="text-2xl" />
      </div>
      <p className="text-sm">User Stats</p>
    </div>
    <div className="bg-[#2b2c2c] p-8 px-0 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/bet-history')}>
      
    <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
      <AiOutlineClockCircle className="text-2xl" />
      </div>
      <p className="text-sm">Bet History</p>
    </div>
    <div className="bg-[#2b2c2c] p-8 px-0 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/game-history')}>
      
    <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
      <GiTrophy className="text-2xl" />
      </div>
      <p className="text-sm">Game History</p>
    </div>
    {/* <div className="bg-[#2b2c2c] p-8 px-0 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/your-history')}>
      
    <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
      <GiTrophy className="text-2xl" />
      </div>
      <p className="text-sm">Your History</p>
    </div> */}
    <div className="bg-[#2b2c2c] p-8 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/notifications')}>
      
    <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
      <AiOutlineNotification className="text-2xl" />
      </div>
      <p className="text-sm">Notifications</p>
    </div>
    <div className="bg-[#2b2c2c] p-8 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/settings-zone')}>
      
    <div className='p-4 mb-2 bg-[#1a1a1a] rounded-full'>
      <AiOutlineSetting className="text-2xl" />
      </div>
      <p className="text-sm">Settings</p>
    </div>
  </div>
</div>
      <NewBottomBar />
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#1a1a1a] p-6 rounded-lg shadow-lg w-80 text-center text-white">
            <p className="text-sm mb-4">
            Need assistance with Chidiya Kabutar? Our dedicated support team is available 24/7 to help with any questions or issues related to gameplay, deposits, withdrawals, or your CK Agent account. We're here whenever you need us!
            </p>
            <a
              href="https://t.me/ChidiyaKabutarManager"
              className="bg-[#34D399] hover:bg-[#2d826f] text-black px-6 py-3 font-bold rounded-md flex items-center justify-center mx-auto w-64"
              target="_blank" 
              rel="noopener noreferrer"
            >
              <FaTelegramPlane className="mr-2 text-lg" /> Contact Support
            </a>
            <div className="mt-4">
              <button onClick={closePopup} className="bg-red-500 text-white py-2 px-4 rounded-md">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdatedProfile;
