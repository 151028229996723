import React, { useState } from "react";
import { FaCheck, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import avatar1 from './images/avatar/1.png';
import avatar2 from './images/avatar/2.png';
import avatar3 from './images/avatar/3.png';
import avatar4 from './images/avatar/4.png';
import avatar5 from './images/avatar/5.png';
import avatar6 from './images/avatar/6.png';
import avatar7 from './images/avatar/7.png';
import avatar8 from './images/avatar/8.png';
import avatar9 from './images/avatar/9.png';
import avatar10 from './images/avatar/10.png';
import avatar11 from './images/avatar/11.png';
import avatar12 from './images/avatar/12.png';
import avatar13 from './images/avatar/13.png';
import avatar14 from './images/avatar/14.png';
import avatar15 from './images/avatar/15.png';
import NewBottomBar from "./newBottomBar";
import Toast from './Toast'; // Import the Toast component

// Map avatar index to names
const avatarNames = [
  'avatar1', 'avatar2', 'avatar3', 'avatar4', 'avatar5',
  'avatar6', 'avatar7', 'avatar8', 'avatar9', 'avatar10',
  'avatar11', 'avatar12', 'avatar13', 'avatar14', 'avatar15'
];

// Sample avatar image URLs (replace with your actual image imports)
const avatars = [
  avatar1, avatar2, avatar3, avatar4, avatar5,
  avatar6, avatar7, avatar8, avatar9, avatar10,
  avatar11, avatar12, avatar13, avatar14, avatar15
];

const AvatarSelector = () => {
  const [selectedAvatar, setSelectedAvatar] = useState(null); // For tracking selected avatar
  const [showToast, setShowToast] = useState(false); // Toast visibility state
  const navigate = useNavigate();

  // Function to handle API request to update avatar
  const updateAvatar = async (avatarName) => {
    const authToken = localStorage.getItem('auth_token'); // Fetch the token from local storage
    if (!authToken) {
      console.error('No auth token found');
      return;
    }

    try {
      const response = await fetch('https://api.chidiyakabutar.com/user/update', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}` // Use the token from local storage
        },
        body: JSON.stringify({ avatar: avatarName })
      });

      if (response.ok) {
        // Handle success
        setShowToast(true);
        setTimeout(() => {
          navigate('/profile'); // Navigate after showing the toast
        }, 1000); // Delay the navigation until after the toast is shown
      } else {
        // Handle error
        console.error('Failed to update avatar');
      }
    } catch (error) {
      console.error('Error updating avatar:', error);
    }
  };

  const handleAvatarClick = (index) => {
    setSelectedAvatar(index); // Update the selected avatar
    const avatarName = avatarNames[index];
    updateAvatar(avatarName); // Update the avatar on the server
  };

  return (
    <div className="flex justify-center items-center bg-black text-white">
      <div className="p-4 bg-black rounded-lg pb-0 mb-24">
        <div className='flex text-left justify-start items-center mb-4'>
          <FaArrowLeft className='font-bold pr-2 text-2xl' 
          onClick={() => navigate(-1)} />
          <h1 className="text-2xl font-bold pl-1">Select Avatar</h1>
        </div>
        <div className="grid grid-cols-3 gap-4 p-4 px-2 pt-1">
          {avatars.map((avatar, index) => (
            <div
              key={index}
              className={`relative p-1 rounded-lg cursor-pointer ${
                selectedAvatar === index ? "border-2 border-white" : "border-2 border-gray-400"
              }`}
              onClick={() => handleAvatarClick(index)}
            >
              <img
                src={avatar}
                alt={`Avatar ${index + 1}`}
                className="w-24 h-24 object-cover rounded-md"
                onClick={() => navigate('/change-avatar')} 
              />
              {selectedAvatar === index && (
                <div className="absolute top-0 left-0 bg-black bg-opacity-50 p-1 rounded-full">
                  <FaCheck className="text-white" />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {showToast && <Toast message="Avatar updated successfully!" onClose={() => setShowToast(false)} />}

      <NewBottomBar />
    </div>
  );
};

export default AvatarSelector;
