import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Logo from './logo.png';
import axios from 'axios';
import api from '../api/utils';

const Button = React.forwardRef(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? 'span' : 'button';
  const buttonClassNames = `inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 ${
    variant === 'ghost' ? 'hover:bg-accent hover:text-accent-foreground' : 'bg-primary text-primary-foreground shadow hover:bg-primary/90'
  } ${size === 'lg' ? 'h-8 rounded-md px-6' : 'h-8 px-3 py-1.5'} ${className}`;

  return <Comp className={buttonClassNames} ref={ref} {...props} />;
});

const Input = React.forwardRef(({ className, type, disabled = false, ...props }, ref) => {
  return (
    <input
      type={type}
      className={`flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm shadow-sm transition-colors placeholder:text-white focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
      ref={ref}
      disabled={disabled}
      {...props}
    />
  );
});

const Message = ({ message, type }) => {
  const baseStyle = 'text-center text-sm mt-4';
  const typeStyles = {
    success: 'text-green-500',
    error: 'text-red-500',
  };

  return message && <div className={`${baseStyle} ${typeStyles[type]}`}><p>{message}</p></div>;
};

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false); // State for terms acceptance
  const [isReferralCodeDisabled, setIsReferralCodeDisabled] = useState(false); // State for referral code input disabled
  const [message, setMessage] = useState({ text: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Extract referral code from query parameters
    const queryParams = new URLSearchParams(location.search);
    const referralCodeParam = queryParams.get('referralCode');
    if (referralCodeParam) {
      setReferralCode(referralCodeParam);
      setIsReferralCodeDisabled(true); // Disable input if referral code is present
    }
  }, [location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ text: '', type: '' });

    if (!acceptTerms) { // Check if terms are accepted
      setMessage({ text: 'You must accept the terms and conditions.', type: 'error' });
      return;
    }

    setIsLoading(true);

    try {
      await api.post('user/signup', {
        name,
        email,
        password,
        phone,
        referral_code: referralCode
      });

      setMessage({ text: 'Signup successful! Redirecting...', type: 'success' });
      setTimeout(() => navigate('/'), 2000); // Redirect to login after 2 seconds
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setMessage({
          text: error.response?.data?.message || 'An unexpected error occurred. Please try again.',
          type: 'error'
        });
      } else {
        setMessage({ text: 'An unexpected error occurred. Please try again.', type: 'error' });
      }
      console.error('Signup error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-stone-900 min-h-screen w-full flex flex-col items-center justify-center px-4 py-12">
      <div className="w-full max-w-md">
        <img src={Logo} width={215} height={215} alt="logo" className="mx-auto mb-6" />
        <div className="w-full p-8 border border-white border-opacity-20 rounded-xl bg-stone-800">
          <h2 className="text-2xl font-bold text-center mb-6 text-white">Sign Up</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border-gray-500 bg-[#171514] text-gray-300 placeholder:text-gray-400"
              placeholder="Name"
              required
            />
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-gray-500 bg-[#171514] text-gray-300 placeholder:text-gray-400"
              placeholder="Email"
              required
            />
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border-gray-500 bg-[#171514] text-gray-300 placeholder:text-gray-400"
              placeholder="Password"
              required
            />
            <Input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="border-gray-500 bg-[#171514] text-gray-300 placeholder:text-gray-400"
              placeholder="Phone"
              required
            />
            <Input
              type="text"
              value={referralCode}
              id="referralCode"
              onChange={(e) => setReferralCode(e.target.value)}
              className="border-gray-500 bg-[#171514] text-gray-300 placeholder:text-gray-400"
              placeholder="Referral Code (optional)"
              disabled={isReferralCodeDisabled} // Disable input based on state
            />
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="acceptTerms"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                required
              />
              <label htmlFor="acceptTerms" className="text-sm text-white">
                I accept the{' '}
                <Link to="/terms" className="text-blue-400 hover:underline">
                  Terms and Conditions
                </Link>
              </label>
            </div>
            <Button
              className="w-full bg-gradient-to-r from-[#f9e6b1] to-[#a69581] text-slate-800"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? 'Signing Up...' : 'Sign Up'}
            </Button>
          </form>
          <Message message={message.text} type={message.type} />
          <div className="mt-6 text-center text-white">
            <p className="text-sm">
              Already have an account?{' '}
              <Link to="/" className="text-blue-400 hover:underline">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
