import React, { useState, useEffect } from 'react';
import { 
    FaUserFriends, FaRupeeSign, FaHistory, FaFileAlt, 
    FaChartPie, FaCopy, FaWallet, FaUniversity, 
    FaMoneyBillWave, FaRegCreditCard, FaLink,
    FaMoneyCheck,
    FaFile,
    FaAngleDoubleLeft,
    FaArrowLeft
} from 'react-icons/fa';
import axios from 'axios';
import { AiOutlineClockCircle, AiOutlineSetting } from 'react-icons/ai'; 
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';
import TopBackNav from './TopBackNav';

const ReferAndEarn = () => {
    const navigate = useNavigate();
    const [referralCode, setReferralCode] = useState('');
    const [copySuccess, setCopySuccess] = useState('');
    const [copyLinkSuccess, setCopyLinkSuccess] = useState('');
    const [error, setError] = useState(null);
    const [earningsData, setEarningsData] = useState({
        today_earnings: 0,
        total_earnings: 0
    });
    const [userProfile, setUserProfile] = useState({
        total_deposits: 0,
    });
    const [todayReport, setTodayReport] = useState({
        earned_commission: 0,
        new_register: 0,
        total_number_of_deposits: 0,
        total_deposit_amount: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('auth_token');
                if (!token) {
                    console.error('No auth_token found in localStorage');
                    return;
                }

                // Fetch today's earnings and total earnings
                const earningsResponse = await fetch(
                    'https://api.chidiyakabutar.com/user/total-referral-earnings',
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const earningsData = await earningsResponse.json();
                console.log('Earnings Data:', earningsData);

                // Fetch total deposits
                const depositsResponse = await fetch(
                    'https://api.chidiyakabutar.com/user/get-deposits',
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const depositsData = await depositsResponse.json();
                console.log('Deposits Data:', depositsData);

                // Fetch referral code
                const referralResponse = await fetch(
                    'https://api.chidiyakabutar.com/user/referral-code',
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const referralData = await referralResponse.json();
                console.log('Referral Code Data:', referralData);

                // Fetch today's report data
                const reportResponse = await fetch(
                    `https://api.chidiyakabutar.com/user/ref-data?start_time=${Math.floor(Date.now() / 1000)}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const reportData = await reportResponse.json();
                console.log('Today\'s Report Data:', reportData);

                // Update state with fetched data
                setEarningsData({
                    today_earnings: earningsData.today_earnings || 0,
                    total_earnings: earningsData.total_earnings || 0
                });
                setUserProfile({
                    total_deposits: depositsData.total_deposits || 0,
                });
                setReferralCode(referralData.referral_code || '');
                setTodayReport({
                    earned_commission: reportData.referred_stats.commission || 0,
                    new_register: reportData.referred_stats.total_users || 0,
                    total_number_of_deposits: reportData.referred_stats.total_number_of_deposit || 0,
                    total_deposit_amount: reportData.referred_stats.total_deposit || 0
                });
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to fetch data');
            }
        };

        fetchData();
    }, [navigate]);

    // Function to copy referral code to clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralCode)
            .then(() => setCopySuccess('Copied!'))
            .catch(() => setCopySuccess('Failed to copy!'));
    };

    // Function to copy session link to clipboard
    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(`https://chidiyakabutar.com/signup?referralCode=${referralCode}`)
            .then(() => setCopyLinkSuccess('Link copied!'))
            .catch(() => setCopyLinkSuccess('Failed to copy link!'));
    };

    return (
        <div className="min-h-screen bg-black text-white font-mont p-4">
          {/* Header */}
          <TopBackNav heading='Refer and Earn' />
    
          {/* Earnings Section */}
          <div className="bg-gradient-to-r from-green-500 to-green-700 rounded-lg p-4 mb-4 font-bold">
            <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="text-yellow-800 text-4xl bg-yellow-400 border-yellow-800 border-8 rounded-full p-3">
                <FaRupeeSign />
              </div>
              <div className="text-center sm:text-left">
                <p className="text-sm">Your total commission</p>
                <p className="text-3xl font-bold">₹ {earningsData.total_earnings || 0}</p>
              </div>
            </div>
    
            <div className="mt-4 bg-gray-800 p-3 rounded-lg flex items-center justify-between border-dotted border-white border-2">
              <p className="tracking-widest">{referralCode || 'Loading...'}</p>
              <div className="flex space-x-2">
                {/* Copy Button */}
                <button onClick={copyToClipboard} className="flex items-center space-x-1 text-white rounded pr-1 border-r-1 border-white">
                  <FaCopy className="text-xl" />
                </button>
                
                {/* Copy Link Button */}
                <button onClick={copyLinkToClipboard} className="flex items-center space-x-1 text-white rounded pl-1">
                  <FaLink className="text-xl" />
                </button>
              </div>
            </div>
    
            {/* Display copy success messages */}
            {copySuccess && alert("Referral Code copied")}
            {copyLinkSuccess && alert("Link copied successfully")}
          </div>
    
          {/* Quick Action Section */}
          <div className="font-bold mb-4">
            <h2 className="text-md font-bold mb-2">Quick Action</h2>
            <div className="grid grid-cols-2 gap-3 bg-[#1a1a1a] rounded-lg p-3 text-center">
              <div className="bg-[#2b2c2c] p-4 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/your-referrals')}>
                <div className='p-3 mb-2 bg-[#1a1a1a] rounded-full'>
                  <FaUserFriends className="text-xl" />
                </div>
                <p className="text-sm">Your Referrals</p>
              </div>
              <div className="bg-[#2b2c2c] p-4 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/your-earnings')}>
                <div className='p-3 mb-2 bg-[#1a1a1a] rounded-full'>
                  <FaMoneyCheck className="text-xl" />
                </div>
                <p className="text-sm">Commission Details</p>
              </div>
              <div className="bg-[#2b2c2c] p-4 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/commission-history')}>
                <div className='p-3 mb-2 bg-[#1a1a1a] rounded-full'>
                  <FaHistory className="text-xl" />
                </div>
                <p className="text-sm">Commission History</p>
              </div>
              <div className="bg-[#2b2c2c] p-4 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/invitation-rules')}>
                <div className='p-3 mb-2 bg-[#1a1a1a] rounded-full'>
                  <FaFileAlt className="text-xl" />
                </div>
                <p className="text-sm">Invitation Rules</p>
              </div>
              <div className="bg-[#2b2c2c] p-4 rounded-lg flex flex-col items-center justify-center py-4" onClick={() => navigate('/commission-ratio')}>
                <div className='p-3 mb-2 bg-[#1a1a1a] rounded-full'>
                  <FaChartPie className="text-xl" />
                </div>
                <p className="text-sm">Commission Ratio</p>
              </div>
            </div>
          </div>
    
          {/* Today's Report Section */}
          <div className="font-bold mb-24">
            <h2 className="text-md font-bold mb-2">Today's Report</h2>
            <div className="bg-[#2b2c2c] rounded-lg p-3">
              <p className="text-sm">Earned Commission: ₹ {todayReport.earned_commission || 0}</p>
              <p className="text-sm">New Register: {todayReport.new_register || 0}</p>
              <p className="text-sm">Total Number of Deposits: {todayReport.total_number_of_deposits || 0}</p>
              <p className="text-sm">Total Deposit Amount: ₹ {todayReport.total_deposit_amount || 0}</p>
            </div>
          </div>
    
          {/* Footer */}
          <NewBottomBar />
        </div>
      );
};

export default ReferAndEarn;
