import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';

const EarningsPage = () => {
  const [earnings, setEarnings] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [hasNext, setHasNext] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState(null); // State for error handling
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Clear previous errors
  
      // Fetch token from localStorage
      const authToken = localStorage.getItem('auth_token');
  
      try {
        const response = await axios.get(`https://api.chidiyakabutar.com/user/earning-page?page=${page}&page_size=10`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
  
        const items = response.data.items || []; // Ensure items is always an array
        if (items.length === 0 && page === 1) {
          // setError('No earnings through referrals.');
        } else {
          setEarnings(items);
          setHasNext(response.data.has_next);
          setTotalCount(response.data.total_count);
        }
      } catch (error) {
        // Handle different types of errors
        if (error.response) {
          // Server responded with a status code other than 2xx
          setError(`Error ${error.response.status}: ${error.response.data.message || 'Something went wrong'}`);
        } else if (error.request) {
          // No response received
          setError('Network error. Please try again later.');
        } else {
          // Error setting up request
          setError('An unexpected error occurred.');
        }
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [page]);
  

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (hasNext && !loading) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <div className="bg-black text-white p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-3">
          <FaArrowLeft 
            className="text-xl cursor-pointer" 
            onClick={() => navigate(-1)} 
          />
          <h1 className="text-xl font-bold">Your Earnings</h1>
        </div>
      </div>

      {/* Error Message */}
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}

      {/* Transaction Table with Scroll and Fixed Height */}
      <div className="bg-[#1a1a1a] rounded-lg p-4 mb-4 overflow-y-auto">
        {loading ? (
          <p className="text-center">Loading...</p>
        ) : earnings.length === 0 ? (
          <p className="text-center">No earnings through referrals.</p>
        ) : (
          <table className="w-full table-auto text-sm">
            <thead className="text-yellow-500 border-b">
              <tr>
                <th className="py-2 text-left">User Detail</th>
                <th className="py-2 text-center">Game ID</th>
                <th className="py-2 text-right">Commission</th>
              </tr>
            </thead>
            <tbody className="text-white">
              {earnings.map((item, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={`border-b cursor-pointer hover:bg-[#3a3a3a] ${expandedRow === index ? 'bg-[#333]' : ''}`}
                    onClick={() => handleRowClick(index)}
                  >
                    <td className="py-2 text-left">{item.name}</td>
                    <td className="py-2 text-center">{item.round}</td>
                    <td className={`py-2 text-right ${item.commission > 0 ? 'text-green-500' : 'text-red-500'}`}>
                      {item.commission}
                    </td>
                  </tr>
                  {expandedRow === index && (
                    <tr className="text-gray-400 text-xs">
                      <td colSpan="3" className="py-2 pl-4 border-b-1 border-t-1 border-white">
                        Phone Number: {item.phone}<br />
                        Date & Time: {new Date(item.created_at * 1000).toLocaleString()}<br />
                        UID: {item.id}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center items-center space-x-2 mb-24">
        <button
          disabled={page === 1 || loading}
          onClick={handlePreviousPage}
          className={`p-2 rounded ${page === 1 ? 'text-gray-600' : 'text-gray-400'} cursor-pointer`}
        >
          <FaArrowLeft />
        </button>
        <p className="text-sm">{page} of {Math.ceil(totalCount / 10) ? Math.ceil(totalCount / 10) : 1}</p>
        <button
          disabled={!hasNext || loading}
          onClick={handleNextPage}
          className={`p-2 rounded ${!hasNext ? 'text-gray-600' : 'text-gray-400'} cursor-pointer`}
        >
          <FaArrowRight />
        </button>
      </div>

      {/* Fixed Bottom Navigation */}
      <NewBottomBar />
    </div>
  );
};

export default EarningsPage;
