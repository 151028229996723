import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaCopy, FaArrowRight, FaArrowLeft as FaArrowBackward } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import api from '../api/utils'; // Import API utility
import NewBottomBar from './newBottomBar';

const Updated_dh = () => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('9'); // Default to 'all'
  const [deposits, setDeposits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  // Function to format status with colors
  const formatStatus = (status) => {
    switch (status) {
      case 0:
        return <span className="text-yellow-500">Pending</span>;
      case 1:
        return <span className="text-green-500">Accepted</span>;
      case 2:
        return <span className="text-red-500">Rejected</span>;
      case 9:
        return <span>All</span>;
      default:
        return <span>Unknown</span>;
    }
  };

  // Custom function to format the timestamp
  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp * 1000); // Convert to milliseconds
      const formattedDate = date.toLocaleDateString('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      const formattedTime = date.toLocaleTimeString('en-IN', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // 24-hour format
      });
      return `${formattedDate} ${formattedTime}`;
    }
    return 'No Timestamp';
  };

  useEffect(() => {
    const fetchDeposits = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const authToken = localStorage.getItem('auth_token');
        const response = await api.get(`/user/get-deposit-forms?status=${filter}&page=${currentPage}&limit=${itemsPerPage}`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });

        // Process deposits to include custom status and format timestamp
        const processedDeposits = response.data.deposit_forms.map(deposit => ({
          ...deposit,
          remarks: deposit.status === 2 ? deposit.remarks : null,
          status: formatStatus(deposit.status),
          created_at: formatTimestamp(deposit.created_at),
        }));

        setDeposits(processedDeposits);
        setTotalPages(Math.ceil(response.data.total_count / itemsPerPage));
      } catch (err) {
        console.error('Error fetching deposits:', err);
        setError('Failed to fetch deposit history');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeposits();
  }, [filter, currentPage]);

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <div className="min-h-screen bg-black text-white p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-3">
          <FaArrowLeft 
            className="text-xl cursor-pointer" 
            onClick={() => navigate(-1)} 
          />
          <h1 className="text-xl font-bold">Deposit History</h1>
        </div>
      </div>

      {/* Filter Dropdown */}
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-semibold">Transactions</h2>
        <select
          className="bg-[#282c2c] text-white p-2 rounded"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
            setCurrentPage(1); // Reset to first page on filter change
          }}
        >
          <option value="9">All</option>
          <option value="0">Pending</option>
          <option value="1">Accepted</option>
          <option value="2">Rejected</option>
        </select>
      </div>

      {/* Transaction Table with Scroll and Fixed Height */}
      <div className="bg-[#1a1a1a] rounded-lg p-4 mb-4 overflow-y-auto" style={{ maxHeight: '300px' }}>
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : deposits.length === 0 ? (
          <p className="text-gray-400 text-center">No results found</p>
        ) : (
          <table className="w-full table-auto">
            <thead className="text-yellow-500 border-b border-gray-500">
              <tr>
                <th className="py-2 text-left">Payment UTR</th>
                <th className="py-2 text-center">Amount</th>
                <th className="py-2 text-right">Status</th>
              </tr>
            </thead>
            <tbody className="text-white">
              {deposits.map((transaction, index) => (
                <React.Fragment key={index}>
                  <tr
                    className="border-b border-gray-700 cursor-pointer"
                    onClick={() => toggleRow(index)}
                  >
                    <td className="py-2 text-left">{transaction.utr}</td>
                    <td className="py-2 text-center">{transaction.amount}</td>
                    <td
                      className={`py-2 text-right ${
                        transaction.status === 'Rejected' ? 'text-red-500' : 'text-yellow-500'
                      }`}
                    >
                      {transaction.status}
                    </td>
                  </tr>
                  {expandedRow === index && (
                    <tr className="text-gray-400 text-sm">
                      <td colSpan="3" className="py-2 pl-4">
                        <p>Payment ID: {transaction.payment_id}{' '}
                          <FaCopy className="inline-block text-gray-500 ml-2 cursor-pointer" />
                        </p>
                        <p>Date & Time: {transaction.created_at}</p>
                        {transaction.remarks && <p className="text-red-500">Remarks: {transaction.remarks}</p>}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center items-center space-x-2 mb-24">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          className={`${
            currentPage === 1 ? 'text-gray-600' : 'text-gray-400'
          } cursor-pointer`}
        >
          <FaArrowBackward />
        </button>
        <p className="text-sm">{currentPage} of {totalPages}</p>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          className={`${
            currentPage === totalPages ? 'text-gray-600' : 'text-gray-400'
          } cursor-pointer`}
        >
          <FaArrowRight />
        </button>
      </div>
      
      <NewBottomBar />

    </div>
  );
};

export default Updated_dh;
