import React, { useState, useEffect } from 'react';
import { FaUniversity, FaArrowLeft } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';
import utr_num from './images/utr_num.jpg'; // Path to the UTR image
import TopBackNav from './TopBackNav';

const baseURL = 'https://api.chidiyakabutar.com'; // Base URL for images

const DepositZone = () => {
  const [selectedGatewayIndex, setSelectedGatewayIndex] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [amount, setAmount] = useState('');
  const [utr, setUtr] = useState('');
  const [showUTRImage, setShowUTRImage] = useState(false); // State for hover effect
  
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the payment methods on component load
    const fetchPaymentMethods = async () => {
      try {
        const response = await fetch(`${baseURL}/user/payment-methods`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        });
        const data = await response.json();
        setPaymentMethods(data.payment_methods || []);
        setSelectedGatewayIndex(0); // Set default selected gateway index
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    };

    fetchPaymentMethods();
  }, []);

  const handleGatewayChange = (index) => {
    setSelectedGatewayIndex(index);
  };

  const handleDepositSubmit = async () => {
    if (!amount || !utr || selectedGatewayIndex === null) {
      alert('Please fill all fields.');
      return;
    }
  
    const selectedGateway = paymentMethods[selectedGatewayIndex];
  
    try {
      const response = await fetch(`${baseURL}/user/deposit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
        body: JSON.stringify({
          amount: parseInt(amount, 10),
          utr,
          payment_id: selectedGateway.id,
        }),
      });
      const result = await response.json();
      alert(result.message || 'Deposit submitted successfully');
  
      // Clear the form fields
      setAmount('');
      setUtr('');
      setSelectedGatewayIndex(null); // Optionally reset the selected payment gateway
  
    } catch (error) {
      console.error('Error submitting deposit:', error);
    }
  };

  const gatewayNames = ['EasyPay', 'PayUBiz', 'Paytm', 'Razorpay', 'instamojo', 'Paypal', 'PhonePe', 'EaseBuzz'];

  const getGatewayName = (index) => {
    return gatewayNames[index] || '';
  };

  return (
    <div className="min-h-screen bg-black text-white p-6 pt-1 relative">
    {showUTRImage && (
      <div className="z-50 absolute top-[20%] left-2/3 transform -translate-x-1/2 mt-2 w-48 p-2 bg-white border border-gray-300 rounded-lg shadow-lg">
        <img
          src={utr_num}
          alt="UTR Example"
          className="w-full h-auto object-cover"
        />
      </div>
    )}
      <TopBackNav heading='Deposit Zone' />

      {/* Payment Gateway Selection */}
      <div className="flex flex-col items-center justify-center">
        <div className='flex justify-start text-left'>
          <p className="text-gray-400 text-sm my-6 mb-0 text-left">Select Payment Gateway</p>
        </div>
        <div className="flex space-x-4 bg-[#1a1a1a] p-4 px-6 rounded-lg w-full justify-between">
          {paymentMethods.map((gateway, index) => (
            <button
              key={gateway.id}
              className={`flex flex-col items-center justify-center bg-[#282c2c] rounded-lg w-[45%] h-16 p-3 ${
                selectedGatewayIndex === index ? 'ring-4 ring-blue-500' : ''
              }`}
              onClick={() => handleGatewayChange(index)}
            >
              <FaUniversity className="text-white text-2xl" />
              <p className="text-xs mt-2">{getGatewayName(index)}</p>
            </button>
          ))}
        </div>

        {/* QR Code Display */}
        {selectedGatewayIndex !== null && (
          <div className="bg-blue-700 w-64 h-64 flex items-center justify-center">
            <img
              src={`${baseURL}/images/payment/${paymentMethods[selectedGatewayIndex].qr_code}`}
              alt="QR Code"
              className="w-full h-full object-cover"
            />
          </div>
        )}

        {/* Gateway Information */}
        {selectedGatewayIndex !== null && (
          <div className="bg-[#1a1a1a] w-full max-w-md mt-6 p-4 rounded-lg text-center px-5">
            <h2 className="text-white font-semibold text-sm">Account</h2>
            <p className="text-gray-400 text-sm pb-2 text-center">
              Minimum Deposit: ₹{paymentMethods[selectedGatewayIndex].min_amount} <br/> Maximum Deposit: ₹{paymentMethods[selectedGatewayIndex].max_amount}
            </p>
            <div className="flex items-center bg-white rounded-md p-2 mt-2">
              <input
                type="text"
                placeholder="Enter ₹ Amount"
                className="flex-grow bg-transparent text-black outline-none placeholder-gray-500"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="flex items-center bg-white rounded-md p-2 mt-2">
              <input
                type="text"
                placeholder="Enter UTR Number"
                className="flex-grow bg-transparent text-black outline-none placeholder-gray-500"
                value={utr}
                onChange={(e) => setUtr(e.target.value)}
              />
              <AiOutlineInfoCircle
                className="text-black ml-2 cursor-pointer"
                onMouseEnter={() => setShowUTRImage(true)}
                onMouseLeave={() => setShowUTRImage(false)}
              />
            </div>
            <button
              className="bg-green-500 w-full text-white py-2 rounded-md mt-3 hover:bg-green-600"
              onClick={handleDepositSubmit}
            >
              Submit
            </button>
          </div>
        )}
      </div>
      <div className="bg-[#1a1a1a] mt-4 p-4 rounded-lg">
        {/* Deposit Guidelines */}
        <div className="mb-20">
          <h4 className="font-semibold text-bold text-underline text-center text-lg">Deposit Guidelines</h4>
          <ul className="list-disc list-inside text-gray-300 text-[1rem]">
            <li>Deposits will be credited<strong> within 10 seconds to 10 minutes</strong>; please be patient during this time.</li>
            <li>Ensure the amount entered <strong>matches</strong> the exact payment amount; mismatched amounts will not be credited, and CK is not responsible for any loss.</li>
            <li>Enter the <strong>matches</strong> correct UTR (Unique Transaction Reference) number; incorrect UTRs may delay or block your payment.</li>
            <li>Only confirmed transactions will be added to your balance; if there are issues, wait 10 minutes before contacting support.</li>
            <li><strong>Fraudulent</strong> payments, <strong>spam</strong> accounts, or <strong>suspicious activity</strong> will result in blocked payments and potential account freezing.</li>
            <li>CK reserves the right to decline payments if deemed illegal or suspicious, and can block or disable accounts in such cases.</li>
          </ul>
        </div>
      </div>
      <NewBottomBar />
    </div>
  );
};

export default DepositZone;
