import React, { useEffect, useState, useMemo } from "react";
import "./ScratchCard.css";
import image1 from './images/ball.png';
import image2 from './images/balti.png';
import image3 from './images/diya.png';
import image4 from './images/cow.png';
import image5 from './images/kabutar.png';
import image6 from './images/kite.png';
import image7 from './images/lattu.png';
import image8 from './images/rabbit.png';
import image9 from './images/rose.png';
import image10 from './images/sun.jpg';
import image11 from './images/titli.png';
import image12 from './images/umbrella.png';
import wonimage from './images/wonpopup.png'
import lostimage from './images/lostpopup.png'
import ball_win from './images/results/results/ball_win.png'
import bucket_win from './images/results/results/bucket_win.png'
import butterfly_win from './images/results/results/butterfly_win.png'
import cow_win from './images/results/results/cow_win.png'
import diya_win from './images/results/results/diya_win.png'
import kabutar_win from './images/results/results/kabutar_win.png'
import kite_win from './images/results/results/kite_win.png'
import lattu_win from './images/results/results/lattu_win.png'
import rabbit_win from './images/results/results/rabbit_win.png'
import rose_win from './images/results/results/rose_win.png'
import sun_win from './images/results/results/sun-win.png'
import umbrella_win from './images/results/results/umbrella_win.png'
import ball_lose from './images/results/results/ball_lose.png'
import bucket_lose from './images/results/results/bucket_lose.png'
import butterfly_lose from './images/results/results/butterfly_lose.png'
import cow_lose from './images/results/results/cow_lose.png'
import diya_lose from './images/results/results/diya_lose.png'
import kabutar_lose from './images/results/results/kabutar_lose.png'
import kite_lose from './images/results/results/kite_lose.png'
import lattu_lose from './images/results/results/lattu_lose.png'
import rabbit_lose from './images/results/results/rabbit_lose.png'
import rose_lose from './images/results/results/rose_lose.png'
import sun_lose from './images/results/results/sun_lose.png'
import umbrella_lose from './images/results/results/umbrella_lose.png'

const ScratchCard = ({ winnerId, onClose, setZIndexControl, gamesesh }) => {
    const [winner, setWinner] = useState({
        image: '',
        label: ''
    });
    const [showModal, setShowModal] = useState(true);
    const [showCard, setShowCard] = useState(true);
    const [message, setMessage] = useState('');

    // Fetch auth_token from localStorage
    const authToken = localStorage.getItem("auth_token");

    const winitems = useMemo(() => [
      { id: 1, label: 'छतरी', image: umbrella_win },
      { id: 2, label: 'बॉल', image: ball_win },
      { id: 3, label: 'सूरज', image: sun_win },
      { id: 4, label: 'दिया', image: diya_win },
      { id: 5, label: 'गाय', image: cow_win },
      { id: 6, label: 'बाल्टी', image: bucket_win },
      { id: 7, label: 'पतंग', image: kite_win },
      { id: 8, label: 'लट्टू', image: lattu_win },
      { id: 9, label: 'गुलाब', image: rose_win },
      { id: 10, label: 'तितली', image: butterfly_win },
      { id: 11, label: 'कबूतर', image: kabutar_win },
      { id: 12, label: 'खरगोश', image: rabbit_win },    
    ], []);
    
    const loseitems = useMemo(() => [
      { id: 1, label: 'छतरी', image: umbrella_lose },
      { id: 2, label: 'बॉल', image: ball_lose },
      { id: 3, label: 'सूरज', image: sun_lose },
      { id: 4, label: 'दिया', image: diya_lose },
      { id: 5, label: 'गाय', image: cow_lose },
      { id: 6, label: 'बाल्टी', image: bucket_lose },
      { id: 7, label: 'पतंग', image: kite_lose },
      { id: 8, label: 'लट्टू', image: lattu_lose },
      { id: 9, label: 'गुलाब', image: rose_lose },
      { id: 10, label: 'तितली', image: butterfly_lose },
      { id: 11, label: 'कबूतर', image: kabutar_lose },
      { id: 12, label: 'खरगोश', image: rabbit_lose },    
    ], []);

    useEffect(() => {
        const fetchWinner = async () => {
            try {
                const response = await fetch('https://api.chidiyakabutar.com/user/get-bet-history?page=1&page_size=1', {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });
    
                const data = await response.json();
                const game = data.items[0];
                const winnerId = game.winner;
                const didWin = game.bets.some(bet => bet.win === true);
                console.log(`${game.round} and ${gamesesh}` )

                if (game.round === gamesesh-1) {
                    setShowCard(true);
                    setMessage(didWin ? "You won!" : "You lost!");
                    const selectedItem = didWin 
                        ? winitems.find((item) => item.id === winnerId)
                        : loseitems.find((item) => item.id === winnerId);
                    
                    if (selectedItem) {
                        setWinner(selectedItem);
                    }
                } else {
                    setShowCard(false);
                    setMessage("Place your bets now...");
                }
    
            } catch (error) {
                console.error("Error fetching bet history:", error);
            }
        };
    
        fetchWinner();
    }, [authToken, winitems, loseitems, gamesesh]);

    return (
        <div className="container p-4 bg-transparent w-full">
            <div className="base p-3 relative w-full bg-gray-800 bg-opacity-75">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 bg-white p-1 px-3 font-bold text-black rounded-full shadow-md"
                > x
                </button>
                <div className="relative w-full h-full mt-1">
                    {showCard ? (
                        <div className="absolute inset-x-0 bottom-5 flex flex-col items-center">
                            <img
                                src={winner.image}
                                alt={winner.label}
                                className="w-full h-full"
                            />
                            {/* <h3 className="absolute bottom-8 text-[2rem] text-red-500 font-bold mt-2">
                                {winner.label}
                            </h3> */}
                        </div>
                    ) : (
                        <div className="absolute inset-0 flex items-center justify-center">
                            <p className="text-white text-3xl font-bold text-center">
                                {message}
                            </p>
                        </div>

                    )}
                </div>
            </div>
        </div>
    );
};

export default ScratchCard;
