import React, { useState, useEffect } from 'react'; 
import { FaTrash, FaPlus, FaArrowLeft } from 'react-icons/fa'; 
import api from '../api/utils';
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';
import { RiBankFill } from 'react-icons/ri';
import { AiOutlineRise } from 'react-icons/ai';
import upi from "./upi.png"
import bank from "./bank.png"

const WithdrawZone = () => {
  const [cards, setCards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [wagerTrue, setWagerTrue] = useState(false);
  const [newPaymentDetails, setNewPaymentDetails] = useState({
    account_number: '',
    ifsc: '',
    bank_name: '',
    phone_number: '',
    account_holder: '',
    upi: '',
  });
  const [paymentType, setPaymentType] = useState('bank');
  const [selectedCard, setSelectedCard] = useState(null);
  const [wagerAmount, setWagerAmount] = useState(0); // To store the wager amount
  const [withdrawAmount, setWithdrawAmount] = useState(''); // To store input withdrawal amount
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const navigate = useNavigate();

  useEffect(() => {
    const fetchWagerAmount = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        const response = await fetch('https://api.chidiyakabutar.com/user/wager', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        console.log(response.data);
        setWagerAmount(data?.amount || 0); // Adjust based on actual response structure
      } catch (error) {
        console.error('Error fetching wager amount:', error);
      }
    };
  
    fetchWagerAmount();
  }, []);
  

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        const response = await api.get('/user/get-account-details', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Log the actual data being fetched
        console.log(response);
        setCards(response.data.items);
  
        // Set the first card as selected by default if there are cards available
        if (response.data.items.length > 0) {
          setSelectedCard(0);
        }
      } catch (error) {
        console.error('Error fetching account details:', error);
      }
    };
    fetchAccountDetails();
  }, []);
  
  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('auth_token'); // Fetch Bearer token from local storage
        const response = await api.get('/user/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserData(response.data.user);
      } catch (error) {
        setError(error);
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    // Fetch user data initially
    fetchUserData();
  }, []);


  const handleDeleteCard = async (index) => {
    const accountId = cards[index].id;
    try {
      const token = localStorage.getItem('auth_token');
      await api.delete('/user/delete-account-details', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { id: accountId },
      });
      setCards(cards.filter((card) => card.id !== accountId));
    } catch (error) {
      console.error('Error deleting account details:', error);
    }
  };
  const handleWithdraw = async () => {
    const amountToWithdraw = parseFloat(withdrawAmount);
  
    // Validation checks
    if (amountToWithdraw < 110) {
      alert('Minimum withdraw amount is 110');
      return;
    }
  
    if (amountToWithdraw > 50000) {
      alert('Maximum withdraw amount is 50000');
      return;
    }
  
    if (amountToWithdraw > userData?.wallet?.balance) {
      alert('Cannot withdraw more than the available balance');
      return;
    }
  
    if (amountToWithdraw > wagerAmount) {
      alert(`You cannot withdraw more than the wager amount of ₹${wagerAmount}`);
      setWagerTrue(true);
      return;
    }
  
    try {
      const token = localStorage.getItem('auth_token');
      const selectedAccountId = cards[selectedCard]?.id; // Assuming selected card has an 'id'
  
      if (!selectedAccountId) {
        alert('Please select a valid payment method.');
        return;
      }
  
      await fetch('https://api.chidiyakabutar.com/user/withdraw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          amount: amountToWithdraw,
          account_id: selectedAccountId,
        }),
      });
  
      // If the request is successful, clear the input and show success alert
      alert('Withdrawal successful');
      setWithdrawAmount(''); // Clear the input after successful withdrawal
    } catch (error) {
      console.error('Error processing withdrawal:', error);
      alert('Withdrawal failed. Please try again.');
    }
  };
  

  const handleInputChange = (e) => {
    setWithdrawAmount(e.target.value);
  };

  return (
    <div className="min-h-screen p-6">
      <div className="max-w-lg mx-auto">
        <div className="flex justify-between items-center mb-6">
          <button className="text-white" onClick={() => window.history.back()}>
            <FaArrowLeft className="text-xl" />
          </button>
          <h1 className="text-2xl text-white font-bold">Withdraw Zone</h1>
          <button
            className="bg-blue-600 text-white rounded-full p-3 hover:bg-blue-700 transition"
            onClick={() => navigate('/payout-settings')}
          >
            <FaPlus />
          </button>
        </div>

        {/* Payment Cards or Add Bank/UPI Button */}
        {cards.length === 0 ? (
          <div className="text-center">
            <button
            onClick={() => navigate('/payout-settings')}
              className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-lg"
            >
              Add Bank/UPI
            </button>
          </div>
        ) : (
          <div className="space-y-4">
            {cards.map((card, index) => (
              <div 
                key={index} 
                onClick={() => setSelectedCard(index)} // Set the selected card index
                className={`bg-[#1a1a1a] text-white p-3 rounded-lg relative shadow-lg cursor-pointer ${selectedCard === index ? 'border-4 border-blue-500' : ''}`} // Add border if selected
              >
                <div className="flex items-center">
                  <div className="bg-[#1a1a1a] p-3 rounded-full mr-3">
                    {/* Conditionally render the image based on UPI or Bank details */}
                    <img
                      src={card.upi_id ? upi : bank}
                      alt={card.upi_id ? "UPI" : "Bank"}
                      className="w-8 h-8"
                    />
                  </div>
                  <div className="flex-1">
                    <h2 className="text-lg font-bold">{card.bank_name || 'UPI'}</h2>
                    {card.account_number ? (
                      <>
                        <p className="text-sm">Account Number: {card.account_number}</p>
                        <p className="text-sm">IFSC Code: {card.ifsc}</p>
                      </>
                    ) : card.upi_id ? (
                      <p className="text-sm">UPI Account: {card.upi_id}</p>
                    ) : (
                      <p className="text-sm text-red-500">No UPI or Bank details available</p>
                    )}
                  </div>
                  {/* Uncomment the button if needed */}
                  {/* <button
                    onClick={() => handleDeleteCard(index)}
                    className="absolute top-3 right-3 text-gray-400 hover:text-red-600"
                  >
                    <FaTrash />
                  </button> */}
                </div>

              </div>
            ))}

          </div>
        )}

        {/* Withdraw Section */}
        <div className="bg-[#1a1a1a] p-6 rounded-lg shadow-lg max-w-md mx-auto text-white mt-4">
          <h2 className="text-center text-xl font-semibold mb-4">Withdrawal Gateway</h2>
          <div className="py-4 pb-0 flex justify-center items-center pb-2">
            <p className="text-lg text-white pr-2 text-center">Available Balance:</p>
            <p className="text-lg font-bold">₹ {userData?.wallet?.balance || '0.00'}</p>
        </div>

          {/* Enter Amount Input */}
          <div className="mb-2">
            <input
              type="number"
              value={withdrawAmount}
              onChange={handleInputChange}
              placeholder="Enter Amount"
              className="bg-[#282c2c] w-full p-2 rounded"
            />
          </div>
          <div className="pb-2 text-center">
            Withdrawable Balance: <span className="text-green-400 font-bold">₹ {wagerAmount}</span>
          </div>
            <p className="text-lg text-white pr-2">Minimum Withdrawal: 110</p>
            <p className="pb-2 text-lg text-white pr-2">Maximum Withdrawal: 50000</p>

          {/* Withdraw Button */}
          <button
            onClick={handleWithdraw}
            className= {`bg-blue-600 text-white w-full py-2 rounded-lg hover:bg-blue-700 transition ${wagerTrue ? `disable` : ``}`}
          >
            Withdraw
          </button>
          <div className="py-4 pb-2">
            Withdraw Lock: 
            <span className='text-yellow-700'>
              {
                userData?.wallet?.balance - wagerAmount <= 0
                ?
                "All your balance is withdrawable"
                :
                `You must bet ${userData?.wallet?.balance - wagerAmount} to make it eligible for withdrawable.`
              }
            </span>
          </div>

        </div>
      </div>

      {/* Withdraw Guidelines */}
      <div className="mt-4 bg-[#1a1a1a] rounded-lg p-4 py-6">
        <h2 className="text-white text-lg font-bold text-center text-underline">Withdraw Guidelines</h2>
        <ul className="text-white list-disc pl-4 mt-2">
          <li>Withdrawals are normally settled within an <strong>hour</strong>, but can take up to <strong>1 day</strong>; you can check the status in the <strong>Withdraw History</strong> section.</li>
          <li>Ensure the withdrawal amount entered matches your available balance; <strong>incorrect amounts</strong> will result in the request being rejected.</li>
          <li>CK will not be responsible for any money loss due to <strong>incorrect accounts or incorrect upi</strong> information provided.</li>
          <li>CK reserves the <strong>right to decline withdrawals</strong> if deemed illegal or suspicious, and can <strong>block or disable</strong> accounts in such cases.</li>
        </ul>
      </div>
    </div>
  );
};

export default WithdrawZone;
