import React, { useState, useEffect } from 'react';
import { FaTrash, FaPlus, FaArrowLeft } from 'react-icons/fa'; // Added FaArrowLeft for Back icon
import api from '../api/utils';
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';
import { RiBankCard2Fill, RiBankFill } from 'react-icons/ri';
import upi from "./upi.png"
import bank from "./bank.png"

const PayoutSettings = () => {
  const [cards, setCards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newPaymentDetails, setNewPaymentDetails] = useState({
    account_number: '',
    ifsc: '',
    bank_name: '',
    phone_number: '',
    account_holder: '',
    upi: '',
  });
  const [paymentType, setPaymentType] = useState('bank'); // State for payment type

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        const response = await api.get('/user/get-account-details', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCards(response.data.items);
      } catch (error) {
        console.error('Error fetching account details:', error);
      }
    };

    fetchAccountDetails();
  }, []);

  const handleDeleteCard = async (index) => {
    const accountId = cards[index].id;
    try {
      const token = localStorage.getItem('auth_token');
      await api.delete('/user/delete-account-details', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { id: accountId },
      });
      setCards(cards.filter((card) => card.id !== accountId));
    } catch (error) {
      console.error('Error deleting account details:', error);
    }
  };

  const validateBankAccountNumber = (accountNumber) => {
    const regex = /^\d{9,18}$/; // Bank account numbers typically range from 9 to 18 digits
    return regex.test(accountNumber);
  };
  
  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\d{10}$/; // Phone number should be exactly 10 digits
    return regex.test(phoneNumber);
  };
  
  const handleModalSubmit = async () => {
    if (paymentType === 'bank') {
      if (!validateBankAccountNumber(newPaymentDetails.account_number)) {
        alert('Please enter a valid bank account number (9-18 digits)');
        return;
      }
      if (!validatePhoneNumber(newPaymentDetails.phone_number)) {
        alert('Please enter a valid 10-digit phone number');
        return;
      }
    }
  
    if (paymentType === 'upi') {
      if (!validatePhoneNumber(newPaymentDetails.phone_number)) {
        alert('Please enter a valid 10-digit phone number');
        return;
      }
    }
  
    try {
      const token = localStorage.getItem('auth_token');
      const endpoint = '/user/add-account-details';
      await api.post(endpoint, newPaymentDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Optionally, update state here if needed
      // setCards([...cards, newPaymentDetails]);
  
      // Close the modal and reset state
      setShowModal(false);
      setNewPaymentDetails({
        account_number: '',
        ifsc: '',
        bank_name: '',
        phone_number: '',
        account_holder: '',
        upi: '',
      });
      setPaymentType('bank'); // Reset to bank type after submission
  
      // Refresh the page
      window.location.reload();
    } catch (error) {
      console.error('Error adding payment details:', error);
    }
  };
  
  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPaymentDetails({
      ...newPaymentDetails,
      [name]: value,
    });
  };

  const showModandNav = () =>{
    setShowModal(true);
    navigate('/payout-settings');
  }

  return (
    <div className="bg-black min-h-screen p-6">
      <div className="max-w-lg mx-auto">
        {/* Back Icon and Title */}
        <div className="flex justify-between items-center mb-6">
        <button className="text-white" onClick={() => window.history.back()}>
            <FaArrowLeft className="text-xl" />
          </button>
          <h1 className="text-2xl text-white font-bold">Payout Settings</h1>
          <button
            className="bg-blue-600 text-white rounded-full p-3 hover:bg-blue-700 transition"
            onClick={() => setShowModal(true)}
          >
            <FaPlus />
          </button>
        </div>

        {/* Payment Cards or Add Bank/UPI Button */}
        {cards.length === 0 ? (
          <div className="text-center">
            <button
              onClick={() => setShowModal(true)}
              className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-lg"
            >
              Add Bank/UPI
            </button>
          </div>
        ) : (
          <div className="space-y-4">
            {cards.map((card, index) => (
              <div key={index} className="bg-[#1a1a1a] text-white p-4 rounded-lg relative shadow-lg">
                <div className="flex items-center">
                  <div className="bg-[#1a1a1a] p-3 rounded-full mr-3">
                    <img
                      src={card.upi_id ? upi : bank}
                      alt={card.upi_id ? "UPI" : "Bank"}
                      className="w-8 h-8"
                    />
                  </div>
                  <div className="flex-1">
                    <h2 className="text-lg font-bold">{card.bank_name || 'UPI'}</h2>
                    {card.account_number ? (
                      <>
                        <p className="text-sm">Account Number: {card.account_number}</p>
                        <p className="text-sm">IFSC Code: {card.ifsc}</p>
                      </>
                    ) : card.upi_id ? (
                      <p className="text-sm">UPI Account: {card.upi_id}</p>
                    ) : (
                      <p className="text-sm text-red-500">No UPI or Bank details available</p>
                    )}
                  </div>
                  <button
                    onClick={() => handleDeleteCard(index)}
                    className="absolute top-3 right-3 text-gray-400 hover:text-red-600"
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#1a1a1a] text-white p-6 rounded-lg w-80">
            <h2 className="text-lg font-semibold mb-4">Add Payment Details</h2>

            {/* Payment Type Selection */}
            <div className="mb-4">
              <label className="inline-flex items-center mr-6">
                <input
                  type="radio"
                  name="payment_type"
                  value="bank"
                  checked={paymentType === 'bank'}
                  onChange={() => setPaymentType('bank')}
                  className="form-radio text-blue-600"
                />
                <span className="ml-2">Bank Details</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="payment_type"
                  value="upi"
                  checked={paymentType === 'upi'}
                  onChange={() => setPaymentType('upi')}
                  className="form-radio text-blue-600"
                />
                <span className="ml-2">UPI Details</span>
              </label>
            </div>

            {/* Conditional Form Fields */}
            {paymentType === 'bank' ? (
              <div className="space-y-4">
                <input
                  type="text"
                  name="bank_name"
                  value={newPaymentDetails.bank_name}
                  onChange={handleInputChange}
                  placeholder="Bank Name"
                  className="w-full p-2 rounded bg-[#282c2c]"
                />
                <input
                  type="number"
                  name="account_number"
                  value={newPaymentDetails.account_number}
                  onChange={handleInputChange}
                  placeholder="Account Number"
                  className="w-full p-2 rounded bg-[#282c2c]"
                />
                <input
                  type="text"
                  name="ifsc"
                  value={newPaymentDetails.ifsc}
                  onChange={handleInputChange}
                  placeholder="IFSC Code"
                  className="w-full p-2 rounded bg-[#282c2c]"
                />
                <input
                  type="text"
                  name="phone_number"
                  value={newPaymentDetails.phone_number}
                  onChange={handleInputChange}
                  placeholder="Phone Number"
                  className="w-full p-2 rounded bg-[#282c2c]"
                />
                <input
                  type="text"
                  name="account_holder"
                  value={newPaymentDetails.account_holder}
                  onChange={handleInputChange}
                  placeholder="Account Holder Name"
                  className="w-full p-2 rounded bg-[#282c2c]"
                />
              </div>
            ) : (
              <div className="space-y-4">
                <input
                  type="text"
                  name="upi"
                  value={newPaymentDetails.upi}
                  onChange={handleInputChange}
                  placeholder="UPI ID"
                  className="w-full p-2 rounded bg-[#282c2c]"
                />
                <input
                  type="text"
                  name="account_holder"
                  value={newPaymentDetails.account_holder}
                  onChange={handleInputChange}
                  placeholder="Account Holder Name"
                  className="w-full p-2 rounded bg-[#282c2c]"
                />
                <input
                  type="text"
                  name="phone_number"
                  value={newPaymentDetails.phone_number}
                  onChange={handleInputChange}
                  placeholder="Phone Number"
                  className="w-full p-2 rounded bg-[#282c2c]"
                />
              </div>
            )}

            <div className="flex justify-end mt-4 space-x-2">
              <button
                onClick={() => setShowModal(false)}
                className="bg-red-600 px-4 py-2 rounded hover:bg-red-700"
              >
                Cancel
              </button>
              <button
                onClick={handleModalSubmit}
                className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-700"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* <NewBottomBar /> */}
    </div>
  );
};

export default PayoutSettings;
