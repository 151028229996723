import React, { useState } from 'react';
import axios from 'axios';

const ChangePasswordModal = ({ onClose }) => {
  const [passwordDetails, setPasswordDetails] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handlePasswordSubmit = async () => {
    try {
      const authToken = localStorage.getItem('auth_token');
      if (!authToken) {
        onClose();
        return;
      }

      if (passwordDetails.newPassword !== passwordDetails.confirmPassword) {
        setError('New passwords do not match.');
        return;
      }

      const response = await axios.patch(
        'hhttps://api.chidiyakabutar.com/user/change-password',
        {
          old_password: passwordDetails.oldPassword,
          new_password: passwordDetails.newPassword,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status !== 200) {
        setError('Failed to change password. Please try again.');
      } else {
        setSuccess('Password changed successfully!');
        setPasswordDetails({
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      }
    } catch (error) {
      setError('An error occurred while changing the password.');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-[#1a1a1a] text-white p-6 rounded-lg w-80">
        <h2 className="text-lg font-bold mb-4">Change Password</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">{success}</p>}
        <div className="mb-4">
          <label className="block mb-2">Old Password</label>
          <input
            type="password"
            value={passwordDetails.oldPassword}
            onChange={(e) => setPasswordDetails({ ...passwordDetails, oldPassword: e.target.value })}
            className="w-full p-2 rounded bg-[#282c2c]"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">New Password</label>
          <input
            type="password"
            value={passwordDetails.newPassword}
            onChange={(e) => setPasswordDetails({ ...passwordDetails, newPassword: e.target.value })}
            className="w-full p-2 rounded bg-[#282c2c]"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Confirm New Password</label>
          <input
            type="password"
            value={passwordDetails.confirmPassword}
            onChange={(e) => setPasswordDetails({ ...passwordDetails, confirmPassword: e.target.value })}
            className="w-full p-2 rounded bg-[#282c2c]"
          />
        </div>
        <div className="flex justify-between">
          <button
            onClick={handlePasswordSubmit}
            className="bg-blue-600 p-2 rounded text-white"
          >
            Change Password
          </button>
          <button
            onClick={onClose}
            className="bg-gray-600 p-2 rounded text-white"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
