import React, { useState, useEffect } from 'react'; 
import { FaArrowLeft, FaCopy, FaArrowRight, FaArrowLeft as FaArrowBackward } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import NewBottomBar from './newBottomBar';

const Updated_ch = () => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('all');
  const [transactions, setTransactions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const authToken = localStorage.getItem('auth_token');
        const statusParam = filter === 'all' ? '9' : filter === 'pending' ? '0' : filter === 'success' ? '1' : '2';
        const response = await fetch(`https://api.chidiyakabutar.com/user/get-referral-transactions?status=${statusParam}&page=${currentPage}&page_size=${itemsPerPage}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        if (response.ok) {
          setTransactions(data.items);
          setTotalPages(Math.ceil(data.total_count / itemsPerPage));
        } else {
          console.error('Failed to fetch transactions', data);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, [filter, currentPage]);

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Transaction ID copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const currentTransactions = transactions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="min-h-screen bg-black text-white p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-3">
          <FaArrowLeft className="text-xl" onClick={() => navigate(-1)} />
          <h1 className="text-xl font-bold">Commission History</h1>
        </div>
      </div>

      {/* Filter Dropdown */}
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-semibold">Transactions</h2>
        <select
          className="bg-[#282c2c] text-white p-2 rounded"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
            setCurrentPage(1); // Reset to first page on filter change
          }}
        >
          <option value="all">All</option>
          <option value="pending">Pending</option>
          <option value="success">Success</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>

      {/* Transaction Table with Scroll and Fixed Height */}
      <div className="bg-[#1a1a1a] rounded-lg p-4 mb-4 overflow-y-auto" style={{ maxHeight: '300px' }}>
        {currentTransactions.length === 0 ? (
          <p className="text-center text-gray-500">No result found</p>
        ) : (
          <table className="w-full table-auto">
            <thead className="text-yellow-500 border-b border-gray-500">
              <tr>
                <th className="py-2 text-left">Date</th>
                <th className="py-2 text-center">Amount</th>
                <th className="py-2 text-right">Status</th>
                {/* <th className="py-2 text-center">Payments</th> */}
              </tr>
            </thead>
            <tbody className="text-white">
              {currentTransactions.map((transaction, index) => (
                <React.Fragment key={transaction.id}>
                  <tr
                    className="border-b border-gray-700 cursor-pointer"
                    onClick={() => toggleRow(index)}
                  >
                    <td className="py-2 text-left">
                      {new Intl.DateTimeFormat('en-GB').format(new Date(transaction.created_at * 1000))}
                    </td>
                    <td className="py-2 text-center">₹ {transaction.amount}</td>
                    <td
                      className={`py-2 text-right ${
                        transaction.status === 0 ? 'text-red-500' : transaction.status === 1 ? 'text-green-500' : 'text-yellow-500'
                      }`}
                    >
                      {transaction.status === 0 ? 'Pending' : transaction.status === 1 ? 'Accepted' : transaction.status === 2 ? 'Rejected' : 'All'}
                    </td>
                  </tr>
                  {expandedRow === index && (
                    <tr className="text-gray-400 text-sm">
                      <td colSpan="4" className="py-2 pl-4">
                        <p>
                          Transaction ID: {transaction.id}{' '}
                          <FaCopy
                            className="inline-block text-gray-500 ml-2 cursor-pointer"
                            onClick={() => copyToClipboard(transaction.id)}
                          />
                        </p>
                        <p>Date & Time: 
                          {new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false,
                          }).format(new Date(transaction.created_at * 1000))}
                        </p>
                        {transaction.remarks && (
                          <p className="text-red-500">Remarks: {transaction.remarks}</p>
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center items-center space-x-2 mb-24">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          className={`${
            currentPage === 1 ? 'text-gray-600' : 'text-gray-400'
          } cursor-pointer`}
        >
          <FaArrowBackward />
        </button>
        <p className="text-sm">{currentPage} of {totalPages ? totalPages : 1}</p>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          className={`${
            currentPage === totalPages ? 'text-gray-600' : 'text-gray-400'
          } cursor-pointer`}
        >
          <FaArrowRight />
        </button>
      </div>

      <NewBottomBar />
    </div>
  );
};

export default Updated_ch;
