import { useState, useEffect } from 'react'; 
import { FaChevronRight, FaUserCircle, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import TopBackNav from './TopBackNav';
import { fetchAvatarImage } from '../api/avatarUtils'; // Import the utility function
import ChangePasswordModal from './ChangePasswordModal'; // Import the new modal component
import axios from 'axios';
import NewBottomBar from './newBottomBar';

const SettingsCenter = () => {
  const navigate = useNavigate();
  const [avatarImage, setAvatarImage] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [ckVersion, setCkVersion] = useState(''); // State for CK Version
  const [loading, setLoading] = useState(false); // State for loading indicator

  useEffect(() => {
    // Define the async function inside useEffect
    const loadAvatarImage = async () => {
      try {
        const avatarImageUrl = await fetchAvatarImage();
        setAvatarImage(avatarImageUrl);
      } catch (error) {
        console.error('Error fetching avatar image:', error);
      }
    };

    const fetchCkVersion = async () => {
      setLoading(true); // Start loading
      const authToken = localStorage.getItem('auth_token');
      
      try {
        const response = await axios.get('https://api.chidiyakabutar.com/user/get-version', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setCkVersion(response.data.version || 'Unknown'); // Set CK Version
      } catch (error) {
        console.error('Error fetching CK version:', error);
        setCkVersion('Error fetching version'); // Set error message
      } finally {
        setLoading(false); // Stop loading
      }
    };

    loadAvatarImage(); // Call the async function
    fetchCkVersion(); // Fetch CK version
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="h-screen p-5 pt-2">
      {/* Header */}
      <div className="flex justify-between items-center w-90 py-3 text-white pb-6">
        <div className='flex justify-center items-center'>
          <FaArrowLeft className='font-bold pr-2 text-2xl' 
            onClick={() => navigate(-1)} />
          <h1 className="text-2xl font-bold">Settings Zone</h1>
        </div>
        <div className="rounded-full bg-blue-600 h-10 w-10 flex items-center justify-center">
          {/* Placeholder for User Icon */}
          <img
            src={avatarImage}
            alt="user"
            className="rounded-full"
          />
        </div>
      </div>
      <div className='flex flex-col items-center w-100'>
        <div className="bg-[#282c2c] text-white w-80 p-5 rounded-lg">
          <div className="flex items-center justify-between mb-4" onClick={() => navigate("/change-avatar")}>
            <img src={avatarImage} alt='avatarImage' className='w-10 h-10 rounded-full' />
            <div className="flex items-center " >
              <span className="mr-3 font-bold">Change Avatar</span>
              <FaChevronRight />
            </div>
          </div>

          <div className="flex justify-between items-center py-3 border-b border-gray-700">
            <span className=' font-bold'>Change Language</span>
            <div className="flex items-center">
              <span className="mr-2">English</span>
              <FaChevronRight />
            </div>
          </div>

          <div className="flex justify-between items-center py-3 border-b border-gray-700">
            <span className=' font-bold'>CK Version</span>
            <span className="text-gray-400">
              {loading ? 'Loading...' : ckVersion}
            </span>
          </div>

          <div className="flex justify-between items-center py-3 border-b border-gray-700 cursor-pointer" onClick={() => setShowPasswordModal(true)}>
            <span className=' font-bold'>Manage Password</span>
            <FaChevronRight />
          </div>

          <div className="flex justify-between items-center py-3" onClick={() => navigate('/payout-settings')}>
            <span className='font-bold'>Manage Payouts</span>
            <FaChevronRight />
          </div>
        </div>
      </div>

      {/* Render the Change Password Modal */}
      {showPasswordModal && (
        <ChangePasswordModal 
          onClose={() => setShowPasswordModal(false)}
        />
      )}
      <div className='text-white'>
       <NewBottomBar />
      </div>
    </div>
  );
};

export default SettingsCenter;
