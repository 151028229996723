import axios from 'axios';
// Ensure you have react-router-dom installed

const API_BASE_URL = "https://api.chidiyakabutar.com";

if (!API_BASE_URL) {
  throw new Error('REACT_APP_API_BASE_URL is not defined in the environment variables');
}

const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000, // 10 seconds timeout
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem('auth_token');
          if (window.location.pathname !== '/login' && window.location.pathname !== '/signup') {
            window.location.replace('/login');
          }
          break;
        case 403:
          console.error('Forbidden: You do not have permission to access this resource');
          break;
        case 404:
          console.error('Not Found: The requested resource does not exist');
          break;
        case 500:
          console.error('Internal Server Error: Please try again later');
          break;
        default:
          console.error(`Unexpected error occurred: ${error.response.status}`);
      }
    } else if (error.request) {
      console.error('No response received from the server');
    } else {
      console.error('Error setting up the request:', error.message);
    }
    return Promise.reject(error);
  }
);

const checkAuth = async () => {
  try {
    const authToken = localStorage.getItem('auth_token');
    const currentPath = window.location.pathname;
    
    if (authToken="" && currentPath !== '/login' && currentPath !== '/signup') {
      window.location.replace('/login');
      return;
    }
  } catch (error) {
    console.error('Auth check failed:', error);
    localStorage.removeItem('auth_token');
    if (window.location.pathname !== '/login' && window.location.pathname !== '/signup') {
      window.location.replace('/login');
    }
  }
};

export default api;
